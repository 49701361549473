import React, {useState}  from 'react';
// @ts-ignore
import quotation from '../../../../static/assets/quotationMark.png';
// @ts-ignore
import "./cards.scss";

const CustomerCard = ({name, job, text, image}) => {

    const [isMobile, setIsMobile] = useState(false);

    const setDimensions = () => {
        if (window.innerWidth <= 670){
            setIsMobile(true);
        }else{
            setIsMobile(false);
        }
    };

    React.useEffect(() => {
        setDimensions();
        window.addEventListener("resize", setDimensions, false);
    }, []);

    if (!isMobile) {
        return (
            <div className="DesktopAlignment">
                <div className="ContentCustomer">
                    <div className="CardContentWrapper">
                        <img className="ImageQuotation" src={quotation} alt="Quotation Mark"/>
                        <div className="ContentTextCustomer">{text}</div>
                    </div>
                </div>
                <div className="ReferenceWrapper">
                    <img className="ImageReference" src={image} alt={name}/>
                    <div className="ReferenceText">
                        <div className="ReferencePerson">{name}</div>
                        <div className="ReferencePersonJob">{job}</div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (    
            <div className="SmartphoneAlignment">
                <div className="ContentCustomer">
                    <div className="CardContentWrapper">
                        <img className="ImageReference" src={image} alt={name}/>
                        <img className="ImageQuotation" src={quotation} alt="Quotation Mark"/>
                        <div className="ContentTextCustomer">{text}</div>
                    </div>
                </div>
                <div className="ReferenceWrapper">
                    <div className="ReferencePerson">{name}</div>
                    <div className="ReferencePersonJob">{job}</div>
                </div>
            </div>
        )
    }
}

export default CustomerCard;