import React from 'react';
// @ts-ignore
import burger from '../../../../static/assets/imagesTestimonials/Testimonial_Avatar.png'
import CustomerCard from './card';

const Burger = () => {

    const Name = "Dr. Ronald Burger";
    const Job = "Diplom-Sportwissenschaftler mit dem Schwerpunkt Leistungssport";
    const Text = `Effektiver kann man Training, Regeneration, Schlaf und Belastung nicht abbilden. 
    In Implays ist alles enthalten, was ich als betreuender Sportwissenschaftler brauche.`;

    return (
        <CustomerCard name={Name} job={Job} text={Text} image={burger}></CustomerCard>
    )
}

export default Burger;