import React from 'react';
// @ts-ignore
import './Article.scss';

export default function Article({Heading, Text, Link}) {
    return (
        <div className="ContentArticle">
            <div className="VerticalLine"></div>
            <div className="SubHeadingArticle">{Heading}</div>
            <div className="ContentTextArticle">{Text}</div>
            <div className="BlogLinkSection7">
                <a className="BlogLink" href={Link} target="_blank">Weiter lesen</a>
            </div>
        </div>
    )
}