import React from 'react';
// @ts-ignore
import './section5.scss';
import MaxSchoeppe from './Cards/MaxSchoeppe';
import AndreaPotratz from './Cards/AndreaPotratz';
import Burger from './Cards/Burger';
import MichaelAngerer from "./Cards/MichaelAngerer";
import ThomasSchoeppe from "./Cards/ThomasSchoeppe";
import AndreSander from "./Cards/AndreSander";
import MartinGrundmann from "./Cards/MartinGrundmann";
import Adlassnig from "./Cards/Adlassnig";

import Slider from './Slider/Slider';

export default function Section5() {

    const componentArray = [
        MaxSchoeppe,
        ThomasSchoeppe,
        AndreSander,
        Burger,
        AndreaPotratz,
        MichaelAngerer,
    ];

    return (
        <div className="ContainerSection5">
            <div className="HeadingSection5">Das sagen unsere Kunden</div>
            <Slider components={componentArray}/>
        </div>
    );
}