import React from 'react';
// @ts-ignore
import max from '../../../../static/assets/imagesTestimonials/Testimonial_Max_Schoeppe.png'
import CustomerCard from './card';

const MaxSchoeppe = () => {

    const Name = "Max Schoeppe";
    const Job = "COO Black Tusk GmbH";
    const Text = `AKQUINET hat uns sehr kompetent und umfassend zum Thema Usability Engineering für das Produkt Implays beraten. 
    Die UX-Spezialisten von AKQUINET haben bereits während des Designs unser Angebot speziell auf die Bedürfnisse
     der Sportler und Trainer abgestimmt und es gleichzeitig geschafft komplexe Abläufe im Sport für unsere Anwender sehr verständlich und anschaulich aufzubereiten.`;

    return (
        <CustomerCard name={Name} job={Job} text={Text} image={max}></CustomerCard>
    )
}

export default MaxSchoeppe;