import React from 'react';
// @ts-ignore
import image from '../../../../static/assets/imagesSection3/icn-requirements.svg';
import ServiceCard from './ServiceCard';

export default function RequirementsEngineering() {    
    const heading = "Requirements Engineering";
    const text = `Bei der Spezifikation eurer (technischen) Anforderungen gibt es hinsichtlich Nachvollziehbarkeit und Prüfbarkeit einiges zu beachten. 
    Wir helfen euch dabei, am Anfang alles richtig zu machen, um bis zum fertigen Produkt Zeit und Geld zu sparen!`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}