import React from 'react';
// @ts-ignore
import thomas from '../../../../static/assets/imagesTestimonials/Testimonial_Thomas_Schoeppe.png'
import CustomerCard from './card';

const ThomasSchoeppe = () => {

    const Name = "Thomas Schoeppe";
    const Job = "Geschäftsführer knowledgepark GmbH";
    const Text = `Die UX-Spezialisten von AKQUINET haben bereits während des Designs unserer Services für medizinische Arbeitsplätze 
    Klick-Dummys Tests mit medizinischen Fachangestellten und Ärzten durchgeführt. 
    Dabei konnten wir deutlich sehen, wie unterschiedlich diese beiden Gruppen die Software nutzen. 
    So wissen wir jetzt beispielsweise, dass die geplanten Individualisierungsfunktionen gerade bei den Ärzten sehr gut ankommen, 
    und können sie speziell auf die Bedürfnisse dieser Nutzergruppe abstimmen.`;

    return (
        <CustomerCard name={Name} job={Job} text={Text} image={thomas}></CustomerCard>
    )
}

export default ThomasSchoeppe;