import React from 'react';
// @ts-ignore
import michael from '../../../../static/assets/imagesTestimonials/Testimonial_Michael_Angerer.png';
import CustomerCard from './card';

const MichaelAngerer = () => {

    const Name = "Michael Angerer";
    const Job = "Product Owner bei der engineeriQ GmbH im Auftrag der HSE365AT";
    const Text = `AKQUINET hat uns sehr kompetent und effizient zum Thema Usability Engineering beraten.`;

    return (
        <CustomerCard name={Name} job={Job} text={Text} image={michael}/>
    )
}

export default MichaelAngerer;