import React from 'react';
// @ts-ignore
import andrea from '../../../../static/assets/imagesTestimonials/Testimonial_Andrea_Potratz.png'
import CustomerCard from './card';

const AndreaPotratz = () => {

    const AndreaName = "Andrea Potratz";
    const AndreaJob = "Humanmedizinerin mit dem Schwerpunkt Sportmedizin";
    const AndreaText = `Mit Implays kann ich mein Racing Team und meine Kunden perfekt monitoren. Auf Basis der
    medizinischen Daten und Indizes kann ich den Trainingsplan ideal auf die einzelnen Sportler
    abstimmen und Potentiale optimieren.`;

    return (
        <CustomerCard name={AndreaName} job={AndreaJob} text={AndreaText} image={andrea}></CustomerCard>
    )
}

export default AndreaPotratz;