import React, {useState} from "react";
import {FaChevronRight, FaChevronLeft} from "react-icons/fa";
import Circle from "../Circle/Circle";
import {useSwipeable} from "react-swipeable";

const Slider = (components: any) => {

    const componentArray = components.components;
    const [current, setCurrent] = useState(0);
    const length = componentArray.length;
    const circleArray = [];

    // mobile: call nextSlide/prevSlide() based on swipe event instead of click event
    // --> updates index --> move to next/prev slide
    // --> new index updates "isActive" variable --> adjust circle color to blue (=active) for the current slide
    const handlers = useSwipeable({
        onSwiped: (eventData) => console.log("User Swiped!", eventData),
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide()
    });
    // populate circle array with circles depending on how many components (= Customer Testimonials) there are
    for (let i = 0; i < length; i++) {
        circleArray[i] = Circle;
    }

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    return (
        <div className="Carousel" {...handlers}>
            {componentArray.map((slide, index) => {
                let CurrentSlide = slide;
                return (
                    <div id="Nummer1" key={index}>
                        {index === current &&
                        <div id="Nummer2" className="SliderWithArrows">
                            <div className="LeftArrow" onClick={prevSlide}/>
                            <CurrentSlide/>
                            <div className="RightArrow" onClick={nextSlide}/>
                        </div>
                        }
                    </div>
                );
            })}

            <div className="circleWrapper">
                {circleArray.map((member, index) => {
                    let CircleToDisplay = member;
                    let isActive: boolean = false;

                    if (current === index) {
                        isActive = true;
                    }

                    return (
                        <div className="circleSpace">
                            <CircleToDisplay key={index} isActive={isActive}/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Slider;