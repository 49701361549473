import React from 'react';
// @ts-ignore
import image from '../../../static/assets/hero-gebrauchstauglichkeit.png';
import './section1.scss';

export default function Section1() {
    return (
        <div className="ContainerSection1">
            <div className="TextblockAndImage">
                <div className="Textblock">
                    <div className="Heading">Digitale Medizinprodukte - Zulassung leicht gemacht</div>
                    <div className="Content">
                        <div className="subcontent1">
                            Das wichtigste Nachweisdokument für die Gebrauchstauglichkeit von Medizinprodukten ist die Gebrauchstauglichkeitsakte. Sie umfasst alle Nachweise, die aus regulatorischer Sicht (IEC 62366 und MDR) im Hinblick auf die Zertifizierung eures Medizinproduktes oder eurer digitalen Gesundheitsanwendung (DiGA) notwendig sind. Wir haben Euch alle Anforderungen in einem Poster zusammengestellt.
                        </div>
                        <a className="DownloadPoster" href="Gebrauchstauglichkeitsakte.png" download="Gebrauchstauglichkeitsakte">Poster herunterladen</a>
                        <div>
                            Unser Ziel ist die gemeinsame Entwicklung von erstklassigen Medizinprodukten und digitalen Gesundheitsanwendungen (DiGA) mit einem multidisziplinären Team aus Healthcare-Experten und Entwicklern, die umfassendes Branchenwissen und tiefe Einblicke in regulatorische Anforderungen bieten. Wir liefern euch einen maßgeschneiderten Service, der von der Anforderungsanalyse über Usability Engineering nach IEC 62366 bis hin zur Entwicklung reicht. Als IT-Dienstleister im Gesundheitswesen konzentrieren wir uns auf Design und Entwicklung, basierend auf echten Nutzungsanforderungen.
                            <br/>
                            <br/>
                            Unser Ansatz sichert nicht nur die regulatorische Konformität eures Medizinproduktes, sondern fördert auch eine optimale Nutzererfahrung und damit euren Markterfolg.
                        </div>
                    </div>
                </div>
                <div className="ImageWrapper">
                    <img className="Image" src={image} alt="Desk Art"/>
                </div>
            </div>
        </div>
    );
}