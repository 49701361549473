import React from 'react';
// @ts-ignore
import '../section7.scss';
import Article from './Article';

export default function Article2() {
    const Heading = "Risikomanagement bei Medizinprodukten";
    const Text = `In einer Ära, in der digitale Technologien das Rückgrat des Gesundheitswesens bilden, spielt die Klassifizierung von Software als Medizinprodukt eine entscheidende Rolle für die Patientensicherheit und Effektivität des Produkts. Unser Artikel beleuchtet die Risiko- und Sicherheitsbewertung medizinischer Software innerhalb der europäischen Medizinprodukte-Verordnung (MDR) und der spezifisch darauf ausgerichteten IEC 62304.
`;
    const Link = "https://blog-de.akquinet.de/2024/04/02/die-bedeutung-der-klassifizierung-in-risikoklassen-und-die-sicherheitsklassen-in-der-medizinprodukteentwicklung/";
    
    return (
        <Article Heading={Heading} Text={Text} Link={Link}></Article>
    )
}