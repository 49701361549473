import React from 'react';
// @ts-ignore
import './section7.scss';
import Article1 from './Cards/Article1';
import Article2 from './Cards/Article2';

export default function Section7() {

    return (
        <div className="ContainerSection7">
            <div className="HeadingSection7">Auf unserem Blog weiterlesen</div>
            <div className="TextblocksRowSection7">
                <Article2/>
                <Article1/>
            </div>
        </div>
    );
}