import React from 'react';
// @ts-ignore
import image from '../../../static/assets/hero-gebrauchstauglichkeit.png';
import './sectionEntwicklung.scss';

export default function SectionEntwicklung() {
    return (
        <div className="ContainerSectionEntwicklung">
            <div className="TextblockAndImageEntwicklung">
                <div className="TextblockEntwicklung">
                    <div className="HeadingEntwicklung">Entwicklungs&shy;partnerschaft</div>
                    <div className="ContentEntwicklung">
                        <div className="subcontentEntwicklung">
                            Als euer Entwicklungspartner begleiten wir euch bei der Realisierung digitaler Medizinprodukte, stets im Einklang mit den neuesten Normen und Standards, insbesondere der IEC 62366 und IEC 62304. Unser Ziel ist es, durch unsere Fachkenntnis und Erfahrung in diesen regulatorischen Bereichen, den Entwicklungsprozess nicht nur zu vereinfachen, sondern auch sicherzustellen, dass eure Produkte den höchsten Qualitäts- und Sicherheitsanforderungen entsprechen. Wir sind hier, um euch durch den gesamten Prozess zu begleiten.
                        </div>
                    </div>
                </div>
                    <div className="TextblockEntwicklung">
                        <div className="HeadingEntwicklung">DiGA-Framework</div>
                        <div className="ContentEntwicklung">
                            <div className="subcontentEntwicklung">
                                Als euer Entwicklungspartner stehen wir mit unserem innovativen DiGA-Framework bereit, um euren Entwicklungsprozess signifikant zu beschleunigen. Unser Ansatz ermöglicht einen sofortigen Einsatz, wodurch ihr von Anfang an Zeit und Ressourcen spart. Doch Flexibilität steht bei uns ebenso im Fokus: Unser Framework ist darauf ausgelegt, maßgeschneidert an eure spezifischen Bedürfnisse angepasst zu werden. So kombinieren wir Geschwindigkeit mit individueller Anpassungsfähigkeit, um euren Projekterfolg zu maximieren.
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}