import React from 'react';
// @ts-ignore
import image from '../../../static/assets/hero-partner.png';
import './section4.scss';
import Statement from './Statement';

import {statement1, statement2, statement3, statement4, statement5, statement6} from './TextStatements';
import {Link} from "gatsby";

export default function Section4() {
    return (
        <div className="ContainerSection4">
            <div className="TextblockAndImageSection4">
                <div className="HeadingSection4">Wir sind euer Partner</div>
                <div className="ImageWrapperSection4">
                    <img className="ImageSection4" src={image} alt="Desk Art"/>
                </div>
                <div className="StatementsSection4">
                    <Statement argument={statement1}/>
                    <Statement argument={statement2}/>
                    <Statement argument={statement3}/>
                    <Statement argument={statement4}/>
                    <Statement argument={statement5}/>
                    <Statement argument={statement6}/>
                </div>
            </div>
            <div className="FooterSection4">Welche unserer Dienstleistungen passen am besten zu eurem Vorhaben?</div>
            <Link className="ContactFormLink KontaktButton MarginButton" to="/Kontakt">Sprechen wir darüber</Link>
        </div>
    );
}