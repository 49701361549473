import React from 'react';
// @ts-ignore
import image from "../../../../static/assets/imagesSection3/icn-documentation.svg";
import ServiceCard from './ServiceCard';

export default function Documentation() {
    const heading = "Dokumentation & Reports";
    const text = `Welche Inhalte müssen in die Gebrauchstauglichkeitsakte? 
    Wie werden die Inhalte am besten aufgebaut und worauf muss bei der Dokumentation geachtet werden? Bei diesen und anderen Fragen könnt ihr auf uns zählen! `;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}