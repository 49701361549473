import React from 'react';
// @ts-ignore
import image from '../../../static/assets/Gebrauchstauglichkeitsakte_Thumbnail.png';
import './section6.scss';

export default function Section6() {

    return (
        <div className="ContainerSection6">
            <div className="TextblockAndImageSection6">
                <div className="ImageWrapperSection6">
                    <div className="ImageBorder">
                        <img className="ImageSection6" src={image} alt="Gebrauchstauglichkeitsakte"/>
                    </div>
                </div>
                <div className="HeadingSection6">Die Gebrauchs&shy;tauglichkeitsakte nach IEC 62366 und MDR</div>
                <div className="ContentSection6">Die Gebrauchs­&shy;tauglichkeitsakte umfasst alle Dokumente, 
                mit denen ihr die gesetzlichen Anforderungen an die Gebrauchstauglichkeit eures Medizinprodukts oder eurer DiGA nachweisen müsst.
                <ol>
                    <li>Was gehört denn alles in die Gebrauchstauglichkeitsakte?</li>
                    <li>Wann starte ich welche Aktivität, um die Akte zu füllen?</li>
                    <li>Wen muss ich involvieren und wie genau soll ich dokumentieren?</li>
                    <li>Wann muss die Akte fertig sein?</li>
                </ol>
                </div>
                <a className="DownloadPosterButton" href="/Gebrauchstauglichkeitsakte">Hier könnt ihr nachlesen, wie wir euch zur Gebrauchs&shy;tauglichkeitsakte unterstützen.</a>
            </div>
        </div>
    );
}