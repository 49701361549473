export let statement1 = "Wir unterstützen Euch da, wo ihr konkret Hilfe benötigt. Ob UX-Design, Usability Testing oder die Erstellung von zulassungsrelevanten Reports - wählt die auf eure Bedürfnisse abgestimmten Services."

export let statement2 = "Vertraut auf unser erfahrenes, multidisziplinäres Team von UX-Expert*innen, UI-Designer*innen, Qualitätsmanager*innen, Branchenexpert*innen und Entwickler*innen."

export let statement3 = "Nutzt unsere langjährige Erfahrung in der Inverkehrbringung von Software als Medizinprodukt sowie von Digitalen Gesundheitsanwendungen (DiGAs)."

export let statement4 = "Als IT-Dienstleister sprechen wir eure Sprache. Wir verstehen Euch, wenn ihr von Client Architekturen, Frameworks und SCSS sprecht und kennen die Herausforderungen in der Umsetzung von Design in Code."

export let statement5 = "Ihr könnt von unserem breiten Know-how im Service Design im klinischen Kontext - sowohl im B2B als auch im B2C Bereich - profitieren."

export let statement6 = "Als große AG sind wir stark vernetzt und stehen in engem Austausch mit Ärzt*innen und Fachgesellschaften wie Hausärzten, Kardiologen und Nephrologen sowie Patient*innen."