import React from 'react';
// @ts-ignore
import Article from './Article.tsx';

export default function Article1() {
    const Heading="Usability Engineering für Medizinprodukte nach IEC 62366";
    const Text=`Die Usability von Medizinprodukten ist entscheidend, um die Sicherheit der Patient*innen zu gewährleisten. Die Norm IEC 62366-1 legt Anforderungen an die Produktentwicklung fest, einschließlich der Identifikation der Nutzer, iterativer Produktentwicklung und summative Evaluation. AKQUINET berät Unternehmen, um Normerfüllung und Effizienz zu optimieren.`
    const Link="https://blog-de.akquinet.de/2024/04/05/usability-engineering-fuer-medizinprodukte-nach-iec-62366/";
    
    return (
        <Article Heading={Heading} Text={Text} Link={Link}/>
    )
}