import React from 'react';
// @ts-ignore
import image from "../../../../static/assets/imagesSection3/icn-prototyping.svg";
import ServiceCard from './ServiceCard';

export default function Prototyping() {
    const heading = "Prototyping & Human-Centered Design";
    const text = `Unser erfahrenes Designteam entwickelt euer User Interface - von den ersten Entwürfen 
    bis hin zu fertigen Oberflächen - iterativ und nutzerzentriert im ständigen Abgleich mit den Anforderungen.`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}