import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import './section2.scss';
// @ts-ignore
import video from '../../../static/assets/16_Usability Engineering_Final_mitOutro.mp4';
// @ts-ignore
import thumbnail from '../../../static/assets/Thumbnail_Video.png';
// @ts-ignore
import thumbnailMobile from '../../../static/assets/ThumbNail_Video_Mobile.png';

export default function Section2() {

    const smartPhoneVideo = ["calc(100vw - 10px)", "calc((100vw - 10px) / 16 * 9)"]; // 100% - Border size
    const desktopVideo = ["calc(40vw - 10px)", "calc((40vw - 10px) / 16 * 9)"]; // 40% - Border size

    const [videoWidth, setVideoWidth] = useState(desktopVideo[0]);
    const [videoHeight, setVideoHeight] = useState(desktopVideo[1]);

    const setDimensions = () => {
        if (window.innerWidth <= 950){
            setVideoWidth(smartPhoneVideo[0]);
            setVideoHeight(smartPhoneVideo[1]);
        }else{
            setVideoWidth(desktopVideo[0]);
            setVideoHeight(desktopVideo[1]);
        }
    };

    React.useEffect(() => {
        setDimensions();
        window.addEventListener("resize", setDimensions, false);
    }, []);

    let textContentSection2 = `Wir begleiten euch norm-konform und leichtgewichtig auf eurem Weg an den Medizinprodukte-Markt. Dabei bieten wir genau den für euch passenden Service entlang des gesamten Entwicklungsprozesses an: Vom Anforderungsmanagement über die fertige Gebrauchstauglichkeitsakte und der Entwicklung!`;

    return (
        <div className="ContainerSection2">
            <div className="ContentSection2">
                <div className="VideoWrapperSection2">
                    <ReactPlayer
                        className="VideoSection2"
                        url={video}
                        light={thumbnail}
                        playing
                        controls
                        width={videoWidth}
                        height={videoHeight}
                    />
                </div>
                <div className="HeadingSection2">Warum wir?</div>
                <div className="TextSection2">{textContentSection2}</div>
            </div>
        </div>
    );
}
