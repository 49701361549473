import React from 'react';
import Landingpage from "../components/Landingpage/Landingpage";

export default function Home() {

    return (
        <div>
            <Landingpage/>
        </div>
    );
}
