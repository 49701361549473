import React from 'react';
// @ts-ignore
import './section3.scss';
import RequirementsEngineering from './Cards/RequirementsEngineering';
import UsabilityTesting from './Cards/UsabilityTesting';
import Riskmanagement from './Cards/Riskmanagement';
import Prototyping from './Cards/Prototyping';
import Documentation from './Cards/Documentation';
import QualityManagement from './Cards/QualityManagement';

export default function Section3() {

    return (
        <div className="ContainerSection3">
            <div className="HeadingSection3">Unsere Services</div>
            <div className="SubHeadingSection3">Digitale Gesundheitsanwendungen (DiGAs) und Medizinprodukte gemeinsam auf den Weg bringen.</div>
            <div className="ServiceCards">
                <RequirementsEngineering/>
                <UsabilityTesting/>
                <Riskmanagement/>
                <Prototyping/>
                <Documentation/>
                {/*<QualityManagement/>*/}
            </div>
        </div>
    );
}