import React from 'react';
// @ts-ignore
import image from "../../../../static/assets/imagesSection3/icn-testing.svg";
import ServiceCard from './ServiceCard';

export default function UsabilityTesting() {
    const heading = "Usability Testing nach IEC 62366";
    const text = `Formative und summative Evaluation sind ein Muss für eure Medizinprodukte - wir testen eure Prototypen genauso wie euer fertiges Produkt. 
    Dazu steht uns eine enorme Bandbreite an Testmethoden und Equipment zur Verfügung sowie bewährte Templates und Reviews für eure Reports.`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}