import React from 'react';
// @ts-ignore
import image from "../../../../static/assets/imagesSection3/icn-risk.svg";
import ServiceCard from './ServiceCard';

export default function Riskmanagement() {
    const heading = "Risikomanagement nach ISO 14971";
    const text = `Wir zeigen euch, wie ihr norm-konform Risiken identifiziert, welche Möglichkeiten ihr zur Risikominderung habt und wie ihr die Risiken überprüfen könnt.`;
    return (
        <ServiceCard image={image} heading={heading} text={text}></ServiceCard>
    )
}