import React from 'react';
import Heading from '../Heading/Heading';
import Section1 from "../Section1/Section1";
import Section2 from '../Section2/Section2';
import Section3 from "../Section3/Section3";
import Section4 from "../Section4/Section4";
import Section5 from "../Section5/Section5";
import Section6 from "../Section6/Section6";
import Section7 from "../Section7/Section7";
import Section8 from "../Section8/Section8";
import Section9 from "../Section9/Section9";
import { Helmet } from "react-helmet";
import SectionEntwicklung from "../SectionEntwicklungsPartnerschaft/SectionEntwicklung";

export default function Landingpage() {

    return (
        <div> 
            <Helmet>
                <title>Digitale Medizinprodukte</title>
            </Helmet>
            <Heading/>
            <Section1/> 
            <Section2/>
            <Section4/>
            <Section5/>
            <Section3/>
            <Section6/>
            <SectionEntwicklung/>
            <Section7/>
            <Section8/>
            <Section9/>
        </div>
    );
}