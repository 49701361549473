import React from 'react';
// @ts-ignore
import '../section5.scss';

const Circle = (props) => {
    return (
        <>
            {props.isActive
                ?
                <div className="circleActive"/>
                :
                <div className="circleInactive"/>
            }
        </>
    );
}

export default Circle;